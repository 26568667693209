<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Flight Card</p>
      <button class="delete" aria-label="close" @click="cancel"></button>
    </header>
    <section class="modal-card-body">
      <!-- Content ... -->
      <ui-input
        name="cs"
        label="Callsign"
        type="text"
        v-model="$v.cs.$model"
        :error="getError(!$v.cs.required)"
        @enter="validate"
      />

      <div class="control">
        <label class="radio">
          <input
            type="radio"
            name="rag"
            value="has-background-link"
            v-model="rag"
            checked
          />
          Departures
        </label>
        <label class="radio">
          <input
            type="radio"
            name="rag"
            value="has-background-danger"
            v-model="rag"
          />
          Arrivals
        </label>
        <label class="radio">
          <input
            type="radio"
            name="rag"
            value="has-background-danger-light"
            v-model="rag"
          />
          Local
        </label>
        <label class="radio">
          <input
            type="radio"
            name="rag"
            value="has-background-success"
            v-model="rag"
          />
          Transit
        </label>
      </div>
      <ui-input name="dpt" type="text" label="DPT" v-model="dpt" />
      <ui-input name="arr" type="text" label="ARR" v-model="arr" />
      <ui-input name="pob" type="text" label="POB" v-model="pob" />
      <ui-input name="rwy" type="text" label="RWY" v-model="rwy" />
      <ui-input name="dtime" type="text" label="DTIME" v-model="dtime" />
      <ui-input name="atime" type="text" label="ATIME" v-model="atime" />
      <ui-input name="qnh" type="text" label="QNH" v-model="qnh" />
      <ui-input name="pos" type="text" label="POS" v-model="pos" />
    </section>
    <footer class="modal-card-foot">
      <button class="button is-primary" @click="validate">
        {{ id ? "Update" : "Add" }}
      </button>
      <button class="button" @click="deleteCard">Delete</button>
      <button class="button" @click="cancel">Cancel</button>
    </footer>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
//cs, dpt, arr, dtime, atime, rwy, qnh, pos, id
function data() {
  return {
    id: null,
    cs: "",
    dpt: "",
    arr: "",
    pob: "",
    dtime: "",
    atime: "",
    rwy: "",
    qnh: "",
    pos: "",
    rag: "has-background-grey-light",
    submitStatus: null,
    //    title: '',
    //    description: '',
    //    date: null,
    //    message: '',
  };
}

export default {
  data() {
    return data();
  },

  computed: {
    values() {
      return this.$data;
    },
  },
  validations: {
    cs: {
      required,
    },
  },
  methods: {
    show(data) {
      Object.assign(this, data);
      this.$el.querySelector("input").focus();
    },

    validate() {
      // console.log('submit!')
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.submitStatus = "OK";
        return this.submit();
      }
    },

    submit() {
      this.$emit("submit", this.values);
      this.reset();
    },

    cancel() {
      this.$emit("cancel", this.values);
      this.reset();
    },
    deleteCard() {
      this.$emit("deleteCard", this.values);
      this.reset();
    },
    reset() {
      Object.assign(this, data());
    },

    getError(err) {
      return err ? "This field is required" : "";
    },
  },
};
</script>

<style lang="scss">
</style>
