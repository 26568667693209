<template>
  <div class="box m-1 p-3" :class="item.rag" @click="edit">
    <table class="table is-fullwidth is-bordered is-narrow">
      <tbody>
        <tr>
          <td class="is-vcentered is-uppercase">{{ item.cs }}</td>
          <td class="is-vcentered is-uppercase">
            <div class="m-0 p-0">{{ item.dpt }}</div>
            <hr class="m-0" />
            <div class="m-0 p-0">{{ item.arr }}</div>
          </td>
          <td class="is-vcentered is-uppercase">{{ item.pob }}</td>
          <td class="is-vcentered is-uppercase">
            <div class="m-0 p-0">{{ item.dtime }}</div>
            <hr class="m-0" />
            <div class="m-0 p-0">{{ item.atime }}</div>
          </td>
          <td>
            <div class="m-0 p-0">{{ item.rwy }}</div>
            <hr class="m-0" />
            <div class="m-0 p-0">{{ item.qnh }}</div>
          </td>
          <td class="is-vcentered is-uppercase">{{ item.pos }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>


<script>
export default {
  name: "AtcCard",
  props: {
    item: Object,
  },
  methods: {
    edit() {
      this.$emit("edit", this.item);
    },
  },
};
</script>