<template>
  <div class="board-container">
    <div>
      <nav class="navbar has-background-light">
        <div class="navbar-brand">
          <div class="navbar-item is-family-primary">ATC BOARD</div>
        </div>
        <div class="navbar-menu">
          <!-- navbar start, navbar end -->
          <div class="navbar-start">
            <!-- navbar items -->
          </div>

          <div class="navbar-end">
            <!-- navbar items -->
            <a class="navbar-item" @click.prevent="reset">Clear</a>
          </div>
        </div>
      </nav>
      <div class="board lists-container">
        <Container
          lock-axis="x"
          orientation="horizontal"
          drag-handle-selector=".list-drag-handle"
          @drop="onListDrop"
        >
          <Draggable v-for="(list, listIndex) in lists" :key="list.id">
            <section class="list-container" ref="list" :data-id="list.id">
              <div
                class="box m-1 has-background-info has-text-white subtitle p-1"
              >
                <span class="list-drag-handle">&#x2630;</span>{{ list.title }}
                <div class="buttons is-pulled-right">
                  <button
                    class="delete"
                    aria-label="close"
                    @click="deleteList(list.id)"
                  ></button>
                </div>
              </div>
              <Container
                group-name="list"
                drag-class="card-ghost"
                drop-class="card-ghost-drop"
                non-drag-area-selector=".icon"
                :animation-duration="100"
                @drop="(e) => onCardDrop(e, list, listIndex)"
              >
                <Draggable v-for="item in list.items" :key="item.id">
                  <FlightCard :item="item" @edit="editItem"> </FlightCard>
                </Draggable>
              </Container>
              <div class="item-entry">
                <ui-item-entry
                  :list-id="list.id"
                  placeholder="Add Card"
                  icon="ellipsis-h"
                  @enter="onAddItem"
                />
              </div>
            </section>
          </Draggable>
          <div class="new-list">
            <ui-item-entry placeholder="Add List" @enter="onAddList" />
          </div>
        </Container>
      </div>

      <ui-modal ref="modal" :active="modal" :cancellable="1" @close="hideModal">
        <ui-item-form
          ref="form"
          @submit="onAddFullItem"
          @cancel="hideModal"
          @deleteCard="deleteCard"
        />
      </ui-modal>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import FlightCard from "@/components/AtcCard.vue";
import { Container, Draggable } from "vue-smooth-dnd";
import UiItemForm from "@/components/UiItemForm";
import UiItemEntry from "@/components/UiItemEntry";
import { makeDropHandler } from "@/utils/plugins";
//cs, dpt, arr, dtime, atime, rwy, qnh, pos, id
export default {
  name: "Board",
  components: {
    FlightCard,
    Container,
    Draggable,
    UiItemEntry,
    UiItemForm,
  },
  data() {
    return {
      modal: false,
      activeListId: null,
    };
  },
  computed: {
    lists() {
      return this.$store.state.board.lists;
    },
  },
  methods: {
    reset() {
      if (confirm("Are you sure you want to reset the board?")) {
        this.$store.commit("reset");
      }
    },
    onAddList({ text }) {
      this.$store.commit("addList", { title: text });
      this.$nextTick(() => {
        const lists = this.$refs.list;
        lists[lists.length - 1].querySelector("input").focus();
      });
    },

    onAddItem({ id, text, more }) {
      if (more) {
        this.activeListId = id;
        this.modal = true;
        this.showModal({ cs: text });
        return;
      }
      this.addItem(id, text, "has-background-primary");
    },

    onAddFullItem(item) {
      item.id
        ? this.$store.commit("updateItem", { itemId: item.id, ...item })
        : this.addItem(
            this.activeListId,
            item.cs,
            item.rag,
            item.dpt,
            item.arr,
            item.pob,
            item.dtime,
            item.atime,
            item.rwy,
            item.qnh,
            item.pos
          );
      this.hideModal();
    },
    addItem(listId, cs, rag, dpt, arr, pob, dtime, atime, rwy, qnh, pos) {
      this.$store.commit("addItem", {
        listId,
        cs,
        dpt,
        arr,
        pob,
        dtime,
        atime,
        rwy,
        qnh,
        pos,
        rag,
      });
    },

    deleteCard(item) {
      this.hideModal();
      this.$store.commit("removeItem", { itemId: item.id });
    },

    deleteList(id) {
      if (confirm("Are you sure you want to delete the list?\n(All cards in list will be deleted also)")) {
        this.$store.commit("removeList", { listId: id });
      }
    },

    editItem(item) {
      this.showModal(item);
    },

    onListDrop: makeDropHandler("onListDropComplete"),

    onListDropComplete: function (src, trg) {
      this.$store.commit("moveList", [src.index, trg.index]);
    },

    onCardDrop: makeDropHandler("onCardDropComplete"),

    /* eslint-disable no-unused-vars */

    onCardDropComplete(src, trg, element, payload) {
      this.$store.commit("moveItem", [
        src.params[1],
        src.index,
        trg.params[1],
        trg.index,
      ]);
    },
    showModal(item) {
      this.modal = true;
      this.$nextTick(() => {
        this.$refs.form.show(item);
      });
    },

    hideModal() {
      this.focusInput(this.activeListId);
      this.modal = false;
    },

    focusInput(listId) {
      const index = this.lists.findIndex((list) => list.id === listId);
      if (index > -1) {
        this.$refs.list[index].querySelector("input").focus();
      }
    },
    /* eslint-enable no-unused-vars */
  },
};
</script>

<style lang="scss">
.custom-bg {
  background-color: blue;
}

.board {
  margin-top: 20px;
  white-space: nowrap;
  > * {
    display: inline-block;
  }

  .new-list {
    margin-top: 10px;
  }
}

$column-width: 480px;

.list-container {
  width: $column-width;
  padding: 10px;
  margin: 5px;
  margin-right: 20px;
  background-color: #f3f3f3;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 1px 1px rgba(0, 0, 0, 0.24);
}

.lists-container {
  > * {
    display: inline-block;
    vertical-align: top;
  }
}

.list-header {
  margin-bottom: 5px;
}

.card {
  margin: 5px;
  background-color: white;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 1px 1px rgba(0, 0, 0, 0.24);
  padding: 10px;
}

.card-ghost {
  transition: 0.25s all;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.12);
  transform: scale(1.1);
}

.card-ghost-drop {
  transform: scale(1);
}

.list-header {
  font-size: 18px;
}

.list-drag-handle {
  cursor: move;
  padding: 5px;
}

.item-entry {
  padding-top: 10px;
  margin-top: 10px;
  border-top: 1px solid #ddd;
}

.new-list {
  width: $column-width;
  margin-left: 10px;
}

.clear-button {
  position: absolute;
  top: 20px;
  right: 20px;
}
table,
td,
th {
  text-align: center;
  width: 16%;
}
</style>