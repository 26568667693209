const {v4: uuidv4 } = require('uuid')
//cs, dpt, arr, dtime, atime, rwy, qnh, pos
export function makeItem (cs, dpt, arr, pob, dtime, atime, rwy, qnh, pos, rag, id = null) {
  id = id || uuidv4()
  return { id, cs, dpt, arr, pob, dtime, atime, rwy, qnh, pos, rag }
}

export function makeList (title, items = []) {
  const id = uuidv4()
  return { id, title, items }
}

export function makeData () {
  return [
    makeList('One', [
      makeItem('1 1','yvr','yyz'),
      makeItem('1 2','sou','ams'),
      makeItem('1 3'),
    ]),
    makeList('Two', [
      makeItem('2 1'),
      makeItem('2 2'),
      makeItem('2 3'),
    ]),
    makeList('Three', [
      makeItem('3 1'),
      makeItem('3 2'),
      makeItem('3 3'),
    ]),
  ]
}